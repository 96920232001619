import { gql } from '@apollo/client';
// Adjust mutation to use the correct input type names
export const PAY_INVOICE = gql`
  mutation PayInvoice(
    $invoiceId: ID!
    $paymentMethod: PaymentMethod!
    $onlinePaymentInput: OnlinePaymentMethodInput
    $manualPaymentInput: ManualPaymentMethodInput
  ) {
    PayInvoice(
      input: {
        invoice_id: $invoiceId
        payment_method: $paymentMethod
        online_payment_input: $onlinePaymentInput
        manual_payment_input: $manualPaymentInput
      }
    ) {
      meta {
        status
        message
        code
      }
      type
      checkout_url
    }
  }
`;

// Update the input types to match your schema
export const ONLINE_PAYMENT_METHOD_INPUT = gql`
  input OnlinePaymentMethodInput {
    amount: Float!
  }
`;

export const MANUAL_PAYMENT_METHOD_INPUT = gql`
  input ManualPaymentMethodInput {
    transactionId: String!
    date: String!
    amount: Float!
  }
`;
