import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Badge, Button, Col, Drawer, Menu, Modal, Row, notification } from "antd";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Logo from "../../../../src/assests/Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  MenuOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import {
  fetchUserProfile,
  logoutUser,
} from "../../../services/Store/NetworkLocations/actions";
import { useDispatch, useSelector } from "react-redux";
import { getCartDetails } from "../../../services/Store/Cart/actions";
import { addDeviceToken } from "../../../services/Store/Notification/actions";
import { receiveMessage, requestForToken } from "../../../firebase";

const Header = () => {
  const isLgScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const innerWidth = window.innerWidth;
  const dispatch = useDispatch();
  const location = useLocation();

  const [current, setCurrent] = useState("/");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const { logoutList } = useSelector((state) => state.networkLocations);
  const isVerifiedCookie = Cookies.get("isVerified");
  const isVerified =
    isVerifiedCookie !== "undefined" && isVerifiedCookie !== undefined
      ? JSON.parse(isVerifiedCookie)
      : false;

  const isVerifiedProfileCookie = Cookies.get("profileVerified");
  const profile_setup_status =
    isVerifiedProfileCookie !== "undefined" &&
    isVerifiedProfileCookie !== undefined
      ? JSON.parse(isVerifiedProfileCookie)
      : false;

  const { cartDetailsList } = useSelector((state) => state.cart);
  const { getProfileList } = useSelector((state) => state.networkLocations);
  Cookies.set("paymentTerm", getProfileList?.paymentTerm?.type);
  Cookies.set("is_suspended", getProfileList?.is_suspended);
  const paymentTerm = Cookies.get("paymentTerm");
  const navigate = useNavigate();
  const deviceId = Cookies.get("deviceId");
  useEffect(() => {
    if (isVerified && profile_setup_status) {
      dispatch(getCartDetails());
      dispatch(fetchUserProfile());
    }
  }, []);

  useEffect(() => {
    const getTokenAndDispatch = async () => {
      receiveMessage();
      const token = await requestForToken();
      if (token) {
        const deviceType = navigator.userAgent;
        let deviceId = Cookies.get("deviceId");
        if (!deviceId) {
          deviceId = uuidv4();
          Cookies.set("deviceId", deviceId, { expires: 180 });
        }
        dispatch(
          addDeviceToken({
            deviceType: deviceType,
            deviceId: deviceId,
            deviceToken: token,
          })
        );
      } else {
        return;
      }
    };
    getTokenAndDispatch();
  }, [dispatch]);
  const onClickMenu = (e) => {
    setCurrent(e.key);
    if (e.key !== "/logout") {
      if (e.key === "/wishlist" && !isVerified && !profile_setup_status) {
        notification.info({
          description: "Please sign in to view your wishlist.",
          duration: 2,
        });
      } else if (
        e.key === "/notification" &&
        !isVerified &&
        !profile_setup_status
      ) {
        notification.info({
          description: "Please sign in to view your notification.",
          duration: 2,
        });
      } else {
        navigate(e.key);
      }
    }
    setDrawerVisible(false);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };
  const handleLogout = () => {
    setIsLogoutModalOpen(true);
  };
  const onCancelLogout = () => {
    setIsLogoutModalOpen(false);
  };
  const onOKLogout = () => {
    dispatch(logoutUser({ deviceId: deviceId }));
  };
  if (logoutList && logoutList.meta && logoutList.meta.code === 200) {
    Cookies.remove("isVerified");
    Cookies.remove("access_token");
    Cookies.remove("profileVerified");
    Cookies.remove("paymentTerm");
    Cookies.remove("is_suspended");
    sessionStorage.removeItem("billingAddress");
    sessionStorage.removeItem("deliveryAddress");
    window.location.replace("/");
  }
  const items = [
    {
      label: isLgScreen ? (
        "Home"
      ) : (
        <div className="flex items-center">
          <span className="mr-2">
            <i className="icon-dashboard" style={{ fontSize: "16px" }}></i>
          </span>
          <span>Home</span>
        </div>
      ),
      key: "/",
    },
    {
      label: isLgScreen ? (
        "Our Products"
      ) : (
        <div className="flex items-center">
          <span className="mr-2">
            <i className="icon-product" style={{ fontSize: "16px" }}></i>
          </span>
          <span>Our Products</span>
        </div>
      ),
      key: "/our-products",
    },
    {
      label: isLgScreen ? (
        "Loyalty Rewards"
      ) : (
        <div className="flex items-center">
          <span className="mr-2">
            <i className="icon-reward-points" style={{ fontSize: "16px" }}></i>
          </span>
          <span>Loyalty Rewards</span>
        </div>
      ),
      key: "/loyalty-reward",
    },
    {
      label: isLgScreen ? (
        <div>
          <span>Our Story</span>
          <span>
            <i className="icon-down-arrow" style={{ fontSize: "20px" }}></i>
          </span>
        </div>
      ) : (
        <div className="flex items-center">
          <span className="mr-2">
            <i className="icon-product" style={{ fontSize: "16px" }}></i>
          </span>
          <span>Our Story</span>
        </div>
      ),
      key: "/ourStory",
      children: [
        {
          label: "About Us",
          key: "/about-us",
          icon: <i className="icon-about-us" style={{ fontSize: "18px" }}></i>,
          className: "subMenuPadding font-century-gothic",
        },
        {
          label: "Contact Us",
          key: "/contact-us",
          icon: (
            <i className="icon-contact-us" style={{ fontSize: "18px" }}></i>
          ),
          className: "subMenuPadding font-century-gothic",
        },
      ],
    },
    isVerified && profile_setup_status
      ? {
          label: isLgScreen ? (
            <div>
              <span>My Profile</span>
              <span>
                <i className="icon-down-arrow" style={{ fontSize: "20px" }}></i>
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              <span className="mr-2">
                <i className="icon-profile" style={{ fontSize: "16px" }}></i>
              </span>
              <span>My Profile</span>
            </div>
          ),
          key: "myProfile",
          children: [
            {
              label: "Dashboard",
              key: "/profile-dashboard",
              icon: (
                <i className="icon-dashboard" style={{ fontSize: "18px" }}></i>
              ),
              className: "subMenuPadding",
            },
            {
              label: "My Account",
              key: "/my-account",
              icon: (
                <i className="icon-profile" style={{ fontSize: "18px" }}></i>
              ),
              className: "subMenuPadding",
            },
            {
              label: "My Orders",
              key: "/my-order",
              icon: (
                <i className="icon-my-order" style={{ fontSize: "18px" }}></i>
              ),
              className: "subMenuPadding",
            },
            {
              label: "Address Book",
              key: "/address-book",
              icon: (
                <i
                  className="icon-address-book"
                  style={{ fontSize: "18px" }}
                ></i>
              ),
              className: "subMenuPadding",
            },
            {
              label: "My Invoice",
              key: "/my-invoice",
              icon: (
                <i className="icon-my-order" style={{ fontSize: "18px" }}></i>
              ),
              className: "subMenuPadding",
            },
            {
              label: "Customer Support",
              key: "/customer-support",
              icon: (
                <i
                  className="icon-customer-support"
                  style={{ fontSize: "18px" }}
                ></i>
              ),
              className: "subMenuPadding",
            },
            {
              label: "Register a Product",
              key: "/product-registration",
              icon: (
                <i className="icon-my-order" style={{ fontSize: "18px" }}></i>
              ),
              className: "subMenuPadding",
            },
            {
              label: "Logout",
              key: "/logout",
              icon: (
                <i className="icon-logout" style={{ fontSize: "18px" }}></i>
              ),
              className: "subMenuPadding",
              onClick: handleLogout,
            },
          ],
        }
      : null,
    {
      label: isLgScreen ? (
        <div>
          <span>
            <Badge
              count={getProfileList?.wishlist_count}
              overflowCount={99}
              style={{ fontWeight: "bold" }}
            >
              <span className="font-bold">
                <i className="icon-wishlist" style={{ fontSize: "30px" }}></i>
              </span>
            </Badge>
          </span>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <div>
            <span className="mr-2">
              <span className="font-bold">
                <i className="icon-wishlist" style={{ fontSize: "16px" }}></i>
              </span>
            </span>
            <span>My Wishlist</span>
          </div>
          <Badge
            count={getProfileList?.wishlist_count}
            overflowCount={99}
            style={{ fontWeight: "bold" }}
            className="justify-self-end"
          ></Badge>
        </div>
      ),
      key: "/wishlist",
    },

    {
      label: isLgScreen ? (
        <div>
          <span>
            <Badge
              count={getProfileList?.unread_notifications}
              overflowCount={99}
              style={{ fontWeight: "bold" }}
            >
              <span className="font-bold">
                <i
                  className="icon-notification-bell"
                  style={{ fontSize: "30px" }}
                ></i>
              </span>
            </Badge>
          </span>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <div>
            <span className="mr-2">
              <i
                className="icon-notification-bell"
                style={{ fontSize: "16px" }}
              ></i>
            </span>
            <span>Notifications</span>
          </div>
          <Badge
            count={getProfileList?.unread_notifications}
            overflowCount={99}
            style={{ fontWeight: "bold" }}
            className="justify-self-end"
          ></Badge>
        </div>
      ),
      key: "/notification",
    },
    isVerified && profile_setup_status
      ? {
          label: isLgScreen ? (
            <div>
              <span>
                <Badge
                  count={cartDetailsList?.total_product}
                  overflowCount={99}
                  style={{ fontWeight: "bold" }}
                >
                  <span>
                    <i className="icon-cart" style={{ fontSize: "30px" }}></i>
                  </span>
                </Badge>
              </span>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <div>
                <span className="mr-2">
                  <i className="icon-cart " style={{ fontSize: "16px" }}></i>
                </span>
                <span>My Cart</span>
              </div>
              <Badge
                count={cartDetailsList?.total_product}
                overflowCount={99}
                style={{ fontWeight: "bold" }}
              ></Badge>
            </div>
          ),
          key: "/cart",
        }
      : {
          label: isLgScreen ? (
            <div className="font-bold text-primary-red">
              <span>Join | Login</span>
            </div>
          ) : (
            <div className="flex items-center">
              <span className="mr-2">
                <i className="icon-logout" style={{ fontSize: "16px" }}></i>
              </span>
              <span>Join | Login</span>
            </div>
          ),
          key: "/login",
        },
  ];

  return (
    <>
      {isLgScreen ? (
        <div className="privateWrapper bg-white myHeaderShadow font-century-gothic">
          <Row>
            <Col xs={4} className="py-4">
              <div className="logo">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Logo"
                    className={` ${
                      innerWidth >= 1300 && innerWidth <= 1400
                        ? "w-32"
                        : innerWidth >= 1580 && innerWidth <= 1680
                        ? "w-36"
                        : "w-40"
                    } ${isSmallScreen ? "w-44" : ""}`}
                  />
                </Link>
              </div>
            </Col>
            <Col xs={20}>
              <Menu
                onClick={onClickMenu}
                selectedKeys={[location.pathname]}
                mode="horizontal"
                items={items}
                className="h-full bg-transparent desktopMenu flex width-full justify-end text-base"
              />
            </Col>
          </Row>
        </div>
      ) : (
        <div className="privateWrapper py-2 relative z-10 bg-white myHeaderShadow">
          <Drawer
            title=""
            placement="right"
            onClose={onClose}
            open={drawerVisible}
            style={{ background: "white" }}
            closeIcon={
              <CloseOutlined className="text-2xl bg-primary-colors-red text-white p-2" />
            }
            className="mobileDrawerMenu"
          >
            <Menu
              onClick={onClickMenu}
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              items={items}
              className="mobileMenu"
            />
          </Drawer>
          <div className="flex items-center justify-between">
            <Link to="/">
              <div className="logo">
                <img src={Logo} alt="Logo" width={100} height={50} />
              </div>
            </Link>
            <MenuOutlined
              className="text-2xl bg-primary-colors-red text-white p-2"
              onClick={showDrawer}
            />
          </div>
        </div>
      )}
      <div>
        <Modal
          title={<div className="text-lg text-center w-full">Log out</div>}
          open={isLogoutModalOpen} // Use visible prop instead of open
          footer={false}
          width={400}
          closeIcon={false}
          centered
        >
          <div className="mt-5">
            <div className="text-gray-500 w-full text-center">
              Are you sure you want to log out ?
            </div>
            <Row gutter={[24]} className="mt-5">
              <Col xs={12}>
                <Button
                  type="default"
                  danger
                  className="w-full"
                  onClick={onOKLogout}
                >
                  Log out
                </Button>
              </Col>
              <Col xs={12}>
                <Button
                  type="primary"
                  className="w-full"
                  onClick={onCancelLogout}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Header;
