import { createSlice } from "@reduxjs/toolkit";
import { addReview, getDashboardDetails, getInvoiceDetails, getInvoiceList, getOrderDetails, getOrdersList,  payInvoice} from "./actions";
import { notification } from "antd";

const initialState = {
  getOrderlist: undefined,
  getOrderloading: false,
  getOrdererror: undefined,

  getOrderDetailslist: undefined,
  getOrderDetailsloading: false,
  getOrderDetailserror: undefined,

  addreviewlist: undefined,
  addreviewloading: false,
  addreviewerror: undefined,

  getDashboardDetailslist: undefined,
  getDashboardDetailsloading: false,
  getDashboardDetailserror: undefined,

  getInvoicelist: undefined,
  getInvoiceloading: false,
  getInvoiceerror: undefined,

  getInvoiceDetailslist: undefined,
  getInvoiceDetailsloading: false,
  getInvoiceDetailserror: undefined,

  paymentInvoiceList: undefined,
  paymentInvoiceloading: false,
  paymentInvoiceerror: undefined,
};
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    applyReview: (state, action) => {
      const idToDelete = action.payload;
      const filteredLine = state?.getOrderDetailslist?.lines?.filter(
        (line) => line?.product?.id === idToDelete
      );
      filteredLine.forEach((item) => {
        item.product.haveUserReviewed = true;
      });
      state.filteredOrderDetails = filteredLine;
    },
  },
  extraReducers: (builder) => {
    builder
      //Order List
      .addCase(getOrdersList.pending, (state) => {
        state.getOrderloading = true;
        state.getOrdererror = null;
      })
      .addCase(getOrdersList.fulfilled, (state, action) => {
        state.getOrderloading = false;
        state.getOrderlist = action.payload;
      })
      .addCase(getOrdersList.rejected, (state, action) => {
        state.getOrderloading = false;
        state.getOrdererror = action.payload;
      })

      .addCase(getOrderDetails.pending, (state) => {
        state.getOrderDetailsloading = true;
        state.getOrderDetailserror = null;
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.getOrderDetailsloading = false;
        state.getOrderDetailslist = action.payload;
      })
      .addCase(getOrderDetails.rejected, (state, action) => {
        state.getOrderDetailsloading = false;
        state.getOrderDetailserror = action.payload;
      })

      // Invoice Details
      .addCase(getInvoiceDetails.pending, (state) => {
        state.getInvoiceDetailsloading = true;
        state.getInvoiceDetailserror = null;
      })
      .addCase(getInvoiceDetails.fulfilled, (state, action) => {
        state.getInvoiceDetailsloading = false;
        state.getInvoiceDetailslist = action.payload;
      })
      .addCase(getInvoiceDetails.rejected, (state, action) => {
        state.getInvoiceDetailsloading = false;
        state.getInvoiceDetailserror = action.payload;
      })

      .addCase(addReview.pending, (state) => {
        state.addreviewloading = true;
        state.addreviewerror = null;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.addreviewloading = false;
        state.addreviewlist = action.payload;
        notification.success({
          message: "Success",
          description: "Review Added Successfully",
          duration: 2,
        });
      })
      .addCase(addReview.rejected, (state, action) => {
        state.addreviewloading = false;
        state.addreviewerror = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })

      .addCase(getDashboardDetails.pending, (state) => {
        state.getDashboardDetailsloading = true;
        state.getDashboardDetailserror = null;
      })
      .addCase(getDashboardDetails.fulfilled, (state, action) => {
        state.getDashboardDetailsloading = false;
        state.getDashboardDetailslist = action.payload;
      })
      .addCase(getDashboardDetails.rejected, (state, action) => {
        state.getDashboardDetailsloading = false;
        state.getDashboardDetailserror = action.payload;
      })

      //Invoice List
      .addCase(getInvoiceList.pending, (state) => {
        state.getInvoiceloading = true;
        state.getInvoiceerror = null;
      })
      .addCase(getInvoiceList.fulfilled, (state, action) => {
        state.getInvoiceloading = false;
        state.getInvoicelist = action.payload;
      })
      .addCase(getInvoiceList.rejected, (state, action) => {
        state.getInvoiceloading = false;
        state.getInvoiceerror = action.payload;
      })

      // Payment Invoice
      .addCase(payInvoice.pending, (state) => {
        state.paymentInvoiceloading = true;
        state.paymentInvoiceerror = null;
      })
      .addCase(payInvoice.fulfilled, (state, action) => {
        state.paymentInvoiceloading = false;
        state.paymentInvoiceList = action.payload;
        notification.success({
          message: "Success",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(payInvoice.rejected, (state, action) => {
        state.paymentInvoiceloading = false;
        state.paymentInvoiceerror = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      });
  },
});
export const { applyReview } = orderSlice.actions;
export default orderSlice.reducer;
