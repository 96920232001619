import { gql } from "@apollo/client";
export const GET_ADDRESS = gql`
  query GetAddresses($first: Int!, $page: Int!,$str:String) {
    GetAddresses(first: $first, page: $page,str:$str) {
			data{
				id
				title
				house_number
				address
				postal_code
				is_default
				full_address
				user{
					id
					name
					paymentTerm{
						name 
						type
					}
				}
				city{
					id
					name
				}
				state{
					id
					name
				}
				country{
					id
					name
				}
			}
       paginatorInfo {
				currentPage
				lastPage
			}
    }
  }
`;

export const GET_ADDRESSES = gql`
  query GetAddress($id:  ID!) {
    GetAddress(id: $id) {
		id
		title
		house_number
		address
		postal_code
		is_default
		full_address
		city{
			id
			name
		}
		state{
			id
			name
		}
		country{
			id
			name
		}
    }
  }
`;