import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import { Checkout_Address, Add_To_Cart, Check_Payment_Status, REMOVE_FROM_CART } from "../../../Mutation/Cart/mutation";
import { GET_CARTDETAILS } from "../../../Query/Cart/query";

export const addToCart = createAsyncThunk(
    'user/addToCart',
    async ({ productId, quantity ,type}, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: Add_To_Cart,
                variables: {
                    productId,
                    quantity,
                    type
                },
            });
            return response.data.AddToCart;
        } catch (error) {
            throw rejectWithValue(error);
        }
    }
);

export const getCartDetails = createAsyncThunk(
    'user/getCartDetails',
    async (_, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: GET_CARTDETAILS,
            });
            return response.data.GetCartDetails;
        } catch (error) {
            throw rejectWithValue(error);
        }
    }
);

export const addCheckoutAddress = createAsyncThunk(
    'user/addCheckoutAddress',
    async ({ checkoutEntity, checkoutId, applyRewardPoints, address_id, name, email_address, contact_number, billing_address, shipping_address ,business_input}, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: Checkout_Address,
                variables: {
                    checkoutEntity,
                    checkoutId,
                    applyRewardPoints,
                    address_id,
                    name,
                    email_address,
                    contact_number,
                    billing_address, // Add billing address variable
                    shipping_address, // Add shipping address variable
                    business_input
                },
            });
            return response.data.Checkout;
        } catch (error) {
            throw rejectWithValue(error);
        }
    }
);

export const checkoutPaymentStatus = createAsyncThunk(
    'user/checkoutPaymentStatus',
    async ({ sessionId }, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: Check_Payment_Status,
                variables: {
                    sessionId,
                },
            });
            return response.data.CheckPaymentStatus;
        } catch (error) {
            throw rejectWithValue(error);
        }
    }
);

export const removeFromCart = createAsyncThunk(
    'user/removeFromCart',
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: REMOVE_FROM_CART, 
                variables: {
                    id,
                },
            });
            return response.data.RemoveFromCart;
        } catch (error) {
            throw rejectWithValue(error);
        }
    }
);
