import { gql } from "@apollo/client";
export const GET_CARTDETAILS = gql`
  query GetCartDetails {
    GetCartDetails{
      id
      total_product
      #discount_formatted_price
      total_shipping_charges
      sub_total
      total
      total_discount
      user {
        name
        is_suspended
        total_reward_points
        total_reward_value
      }
      apply_reward_points
      formatted_details {
        sub_total
        formatted_sub_total
        total
        formatted_total
        total_discount
        formatted_total_discount
        total_shipping_charges
        formatted_total_shipping_charges
        total_discount_price
        formatted_total_discount_price
        total_reward_points
        formatted_total_reward_points
        total_reward_value
        formatted_total_reward_value
        total_applied_goodies_points
        formatted_total_applied_goodies_points
        total_reward_points
        total_reward_points_label
        remaining_amount_to_pay
        formatted_remaining_amount_to_pay
        total_earned_points
        formatted_total_earned_points
        total_used_points
        formatted_total_used_points
        total_selling_price
        formatted_total_selling_price
      }
      lines{
        id
        quantity
        product{
          id
          slug
          name
          sub_title
          thumbnail{
            path
          }
          product_variant {
            type
            id
            has_rewards
            reward_points
            goodies_points
            applicable_reward_points
          }
          original_price
          formatted_price
        }
      }
    }
  }`;