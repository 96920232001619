import { gql } from "@apollo/client";
export const Get_Profile = gql`
  query user {
    user {
      is_suspended
      paymentTerm {
        name
        type
      }
      avatar
      name
      documents {
        client_original
        extension
        id
        mime_type
        name
        path
        size
        type
        created_at
        updated_at
      }
      unread_messages
      user_reward_details {
        current_tier
        current_tier_image
        next_tier
        remaining_points_to_next_tier
      }
      company_name
      created_at
      email
      email_verified_at
      id
      is_verified
      name
      phone_number
      profile_setup_status
      updated_at
      notification_preferences {
        title
        id
        info
        pivot {
          active
        }
      }
      is_social_user
      wishlist_count
      unread_notifications
      cart{
        total_product
      }
    }
  }
`;