import { gql } from "@apollo/client";

export const Add_To_Cart = gql`
  mutation AddToCart(
    $productId: ID!
    $quantity: Int!
    $type: AddToCartTypeEnum!
  ) {
    AddToCart(productId: $productId, quantity: $quantity, type: $type) {
      meta {
        code
        status
        message
      }
      showToast
      cart {
        id
        total_product
        #discount_formatted_price
        total_shipping_charges
        sub_total
        total
        total_discount
        total_shipping_charges
        apply_reward_points
        user {
          name
          total_reward_points
          total_reward_value
          wishlist_count
        }
        apply_reward_points
        formatted_details {
          sub_total
          formatted_sub_total
          total
          formatted_total
          total_discount
          formatted_total_discount
          total_shipping_charges
          formatted_total_shipping_charges
          total_discount_price
          formatted_total_discount_price
          total_reward_points
          formatted_total_reward_points
          total_reward_value
          formatted_total_reward_value
          total_applied_goodies_points
          formatted_total_applied_goodies_points
          total_reward_points
          total_reward_points_label
          remaining_amount_to_pay
          formatted_remaining_amount_to_pay
          total_earned_points
          formatted_total_earned_points
          total_used_points
          formatted_total_used_points
          total_selling_price
          formatted_total_selling_price
        }
        lines {
          id
          quantity
          product {
            id
            name
            sub_title
            thumbnail {
              path
            }
            product_variant {
              type
              id
              has_rewards
              reward_points
              goodies_points
              applicable_reward_points
            }
            original_price
            formatted_price
            cart_details {
              min_limit
              adjust_count
              max_limit
            }
          }
        }
      }
    }
  }
`;

export const Checkout_Address = gql`
  mutation Checkout(
    $checkoutEntity: String!
    $checkoutId: ID!
    $applyRewardPoints: Int!
    $address_id: ID
    $name: String!
    $email_address: String!
    $contact_number: String!
    $billing_address: CheckoutAddressInput!   # Updated
    $shipping_address: CheckoutAddressInput!  # Updated
    $business_input: BusinessCheckoutInput
  ) {
    Checkout(
      checkoutEntity: $checkoutEntity
      checkoutId: $checkoutId
      applyRewardPoints: $applyRewardPoints
      input: {
        address_id: $address_id
        name: $name
        email_address: $email_address
        contact_number: $contact_number
      }
      billing_address: $billing_address
      shipping_address: $shipping_address
      business_input:$business_input
    ) {
      meta {
        status
        message
        code
      }
      type
      checkout_url
      invoice {
        purchase_order_no
        serial_no
        user {
          name
        }
        order {
          serial_no
        }
        paymentTerm {
          type
          name
        }
        formatted_details {
          order_total
        }
        order_total
        is_paid
      }
    }
  }
`;

export const Check_Payment_Status = gql`
  mutation CheckPaymentStatus($sessionId:String!) {
    CheckPaymentStatus(
    sessionId: $sessionId
  ){
      meta{
        status
        message
        code
      }
      type
      transaction {
        success
        type
        driver
        amount
        sub_total
        currency
        reference
        status
        notes
        formatted_details{
          amount
          captured_at
        }
      }
      invoice {
        id
        serial_no
      }
      checkout_data {
        user_id
        checkout_type
        checkout_id
      }
      order {
        id
        serial_no
        user{
          id
          name
        }
        status
        reference
        sub_total
        discount_total
        shipping_total
        total
        notes
        placed_at
        formatted_details {
          sub_total
          total
          total_discount
          total_shipping_charges
          total_discount_price
        }
        lines{
          id
          type
          formatted_details {
            discount_price
            original_price
          }
          identifier
          unit_price
          unit_quantity
          quantity
          sub_total
          discount_total
          tax_total
          total
          notes
          created_at
          updated_at
          meta {
            product {
              serial_no
              name
            }
          }
          line_items {
            serial_no
          }
        }
      }
    }
  }`;

export const REMOVE_FROM_CART = gql`
  mutation RemoveFromCart($id: ID!) {
    RemoveFromCart(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;
