import { lazy } from "react";
import Cookies from "js-cookie";
const isVerifiedCookie = Cookies.get("isVerified");
const isprofileVerified = Cookies.get("profileVerified");
const isVerified =
  isVerifiedCookie !== "undefined" && isVerifiedCookie !== undefined
    ? JSON.parse(isVerifiedCookie)
    : false;
const profileVerified =
  isprofileVerified !== "undefined" && isprofileVerified !== undefined
    ? JSON.parse(isprofileVerified)
    : false;
const PublicRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../pages/auth/Login")),
    exact: true,
    show: isVerified && profileVerified ? false : true,
    title: "Fischer - Login",
  },
  {
    path: "/register",
    component: lazy(() => import("../pages/auth/Register")),
    exact: true,
    show: isVerified && profileVerified ? false : true,
    title: "Fischer - Register",
  },
  {
    path: "/email",
    component: lazy(() => import("../pages/auth/EmailVerification")),
    exact: true,
    show: isVerified && profileVerified ? false : true,
    title: "Fischer - Email Verification",
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../pages/auth/forgotPassword")),
    exact: true,
    show: isVerified && profileVerified ? false : true,
    title: "Fischer - Forget Password",
  },
  {
    path: "/new-password",
    component: lazy(() => import("../pages/auth/newPassword")),
    exact: true,
    show: isVerified && profileVerified ? false : true,
    title: "Fischer - New Password",
  },
  {
    path: "/user-detail",
    component: lazy(() => import("../pages/auth/UserDetails")),
    exact: true,
    show: isVerified && profileVerified ? false : true,
    title: "Fischer - User Details",
  },
  {
    path: "/",
    component: lazy(() => import("../pages/dashboard")),
    exact: true,
    show: true,
    title: "Fischer - Dashboard",
  },
  {
    path: "/our-products",
    component: lazy(() => import("../pages/ourProduct")),
    exact: true,
    show: true,
    title: "Fischer - Our Products",
  },
  {
    path: "/product-detail/:slug",
    component: lazy(() => import("../pages/productDetails")),
    exact: true,
    show: true,
    title: "Fischer - Product Details",
  },
  {
    path: "/about-us",
    component: lazy(() => import("../pages/aboutus")),
    exact: true,
    show: true,
    title: "Fischer - About Us",
  },
  {
    path: "/contact-us",
    component: lazy(() => import("../pages/contactus")),
    exact: true,
    show: true,
    title: "Fischer - Contact Us",
  },
  {
    path: "/loyalty-reward",
    component: lazy(() => import("../pages/loyaltyReward")),
    exact: true,
    show: true,
    title: "Fischer - Loyalty Rewards",
  },
  {
    path: "/terms-and-conditions",
    component: lazy(() => import("../pages/cmsslugPage")),
    exact: true,
    show: true,
    title: "Fischer - Terms Conditions",
  },
  {
    path: "/privacy-policy",
    component: lazy(() => import("../pages/cmsslugPage")),
    exact: true,
    show: true,
    title: "Fischer - Privacy Policy",
  },
  {
    path: "/about-us",
    component: lazy(() => import("../pages/cmsslugPage")),
    exact: true,
    show: true,
    title: "Fischer - About Us",
  },
  {
    path: "*",
    component: lazy(() => import("../pages/Page404")),
    exact: true,
    show: true,
    title: "Fischer - Page404",
  },
];

const PrivateRoutes = [
  {
    path: "/my-account",
    component: lazy(() => import("../pages/myAccount")),
    exact: true,
    title: "Fischer - My Account",
  },
  {
    path: "/my-invoice",
    component: lazy(() => import("../pages/ListInvoice")),
    exact: true,
    title: "Fischer - My Invoices",
  },
  {
    path: "/address-book",
    component: lazy(() => import("../pages/profile")),
    exact: true,
    title: "Fischer - Address Book",
  },
  {
    path: "/add-new-address",
    component: lazy(() => import("../pages/addNewAddress")),
    exact: true,
    title: "Fischer - Add Address",
  },
  {
    path: "/edit-address/:id",
    component: lazy(() => import("../pages/addNewAddress")),
    exact: true,
    title: "Fischer - Edit Address",
  },
  {
    path: "/cart",
    component: lazy(() => import("../pages/cart")),
    exact: true,
    title: "Fischer - Cart",
  },
  {
    path: "/checkout",
    component: lazy(() => import("../pages/checkout")),
    exact: true,
    title: "Fischer - Checkout",
  },
  {
    path: "/product-registration",
    component: lazy(() => import("../pages/productRegistration")),
    exact: true,
    title: "Fischer - Product Registration",
  },
  {
    path: "/profile-dashboard",
    component: lazy(() => import("../pages/profileDashboard")),
    exact: true,
    title: "Fischer - Profile Dashboard",
  },
  {
    path: "/my-order",
    component: lazy(() => import("../pages/myOrders")),
    exact: true,
    title: "Fischer - My Orders",
  },
  {
    path: "/order-detail/:serial_no",
    component: lazy(() => import("../pages/orderDetail")),
    exact: true,
    title: "Fischer - Order Detail",
  },
  {
    path: "/invoice-detail/:serial_no",
    component: lazy(() => import("../pages/invoiceDetail")),
    exact: true,
    title: "Fischer - Invoice Detail",
  },
  {
    path: "/payment-status",
    component: lazy(() => import("../pages/paymentStatus")),
    exact: true,
    title: "Fischer - Payment Status",
  },
  {
    path: "/wishlist",
    component: lazy(() => import("../pages/wishlist")),
    exact: true,
    title: "Fischer - Wishlist",
  },
  {
    path: "/notification",
    component: lazy(() => import("../pages/notification")),
    exact: true,
    title: "Fischer - Notification",
  },
  {
    path: "/customer-support",
    component: lazy(() => import("../pages/CustomerSupport")),
    exact: true,
    title: "Fischer - Customer Support",
  },
  {
    path: "/customer-support/add-ticket",
    component: lazy(() => import("../pages/AddNewTicket")),
    exact: true,
    title: "Fischer - Add New Ticket",
  },
  {
    path: "/customer-support/compose-mail",
    component: lazy(() => import("../pages/ComposeNewMail")),
    exact: true,
    title: "Fischer - Compose New Mail",
  },

  {
    path: "*",
    component: lazy(() => import("../pages/Page404")),
    exact: true,
    title: "Fischer - Page404",
  },
];

export { PublicRoutes, PrivateRoutes };
