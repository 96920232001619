import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";
import { onError } from "@apollo/client/link/error";
import { notification } from "antd";
const API_URL = process.env.REACT_APP_API_URL;
const DOMAIN = process.env.REACT_APP_DOMAIN;
const httpLink = createHttpLink({
  uri: `${API_URL}/${DOMAIN}`,
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("access_token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((extensions) => {
      if (extensions && extensions.message === "Unauthenticated.") {
        // Redirect to login page when unauthenticated
        Cookies.remove("isVerified");
        Cookies.remove("access_token");
        Cookies.remove("profileVerified");
        Cookies.remove("user_Id");
        Cookies.remove("paymentTerm");
        Cookies.remove("is_suspended");
        window.location.replace("/login");
      }
    });
    if (
      graphQLErrors[0]?.extensions?.reason === 403 &&
      graphQLErrors[0]?.message === "Access Denied!"
    ) {
      
      Cookies.remove("isVerified");
      Cookies.remove("access_token");
      Cookies.remove("profileVerified");
      Cookies.remove("user_Id");
      Cookies.remove("is_suspended");
      Cookies.remove("paymentTerm");
      setTimeout(() => {
        window.location.replace("/login");
      }, 2000);
    }
  }
  if (networkError) {
    // Handle network errors here
    notification.info({
      message: "Something went wrong, network errors!",
      duration: 1,
    });
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export default client;
